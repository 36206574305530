<template>
  <div id="main">
    <div class="flex flex-col items-center mt-4">
      <svg class="w-10 h-10 text-gray-700 fill-current text-gray-700" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M460.1 353.4l-70.5 13.5 4.1 20.7 47.2-9-11 16.2c-42.5 52.8-105.7 83.9-174.1 83.9C132.5 478.7 33 379.2 33 255.9v-8.3l-20.7-1v9.3c0 134.7 108.8 243.5 243.5 243.5 74.6 0 144-33.2 191.7-92.2l9.4-14.3 8.2 43.3 20.7-4.1-13.5-70.5c-.8-5.1-5.9-9.2-12.2-8.2zM256 12.5c-74.6 0-144 33.2-191.7 92.2l-8.4 12.8-8.1-42.9L27 78.8l12.4 70.5c1 5.2 6.3 9.3 13.5 8.3l70.5-13.5-4.1-20.7-47.6 9L82 117.2c42.4-52.9 105.6-84 174-84 123.3 0 222.8 99.5 222.8 222.8v8.3l20.7 1V256c0-134.7-108.8-243.5-243.5-243.5z"/><path d="M137.9 155.5h20.7v200h-20.7zM209.4 222.8h20.7v133.7h-20.7zM281.9 189.7h20.7v166.8h-20.7zM353.4 222.8h20.7v133.7h-20.7z"/></svg>
      <div class="text-xl text-left tracking-tight text-gray-700">Shop Stats</div>
      <!-- <div class="-mt-2 text-xl text-left tracking-tight text-gray-700">Stats</div> -->
      <div class="-mt-1 text-l text-left tracking-tight text-gray-600">for</div>
      <div class="-mt-1 text-xl text-left tracking-tight text-gray-700">EP</div>
    </div>

    <!-- <h3 v-bind:class="{'text-green-400': connected, 'text-red-400': !connected}" class="text-sm italic text-center tracking-tight mt-8">{{connected ? 'Connected!' : 'Disconnected!'}}</h3> -->
    <!-- <div class="pt-2 text-sm text-center text-red-600" v-if="error">
         {{error}}
         </div> -->
    <div class="w-72 mx-auto mt-2 py-6 border rounded-lg border-grey-400 shadow text-gray-700">
      <!-- <h1 class="text-xl font-semibold mx-auto text-center text-gray-700">Најави се</h1> -->
      <form @submit.prevent="login">
        <div class="flex items-start justify-start flex-wrap">
          <div class="px-8 w-full">
            <label class="block text-sm px-1">Корисник</label>
            <input type="string" class="mt-1 w-full text-sm px-1 py-1 rounded-lg border focus:border-gray-500 outline-none" placeholder="Корисничко име" v-model="username" />
          </div>
          <div class="mt-4 px-8 w-full">
            <label class="block text-sm px-1" >Лозинка</label>
            <input type="password" class="mt-1 w-full text-sm px-1 py-1 rounded-lg border focus:border-gray-500 outline-none" placeholder="Лозинка" v-model="password" />
          </div>
          <div class="mt-4 px-8 w-full">
            <label class="block text-sm px-1" >Пин</label>
            <input type="password" class="mt-1 w-full text-sm px-1 py-1 rounded-lg border focus:border-gray-500 outline-none" placeholder="Пин" v-model="pin" />
          </div>
        </div>
        <button class="mx-auto mt-8 flex hover:bg-gray-300 border border-gray-500 rounded-sm shadow-md py-2 px-6 hover:text-gray-900 text-gray-600 transition duration-300 text-sm uppercase font-bold tracking-wide focus:outline-none" type="submit">
          <div>Најава</div>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data: () => ({
    username: '',
    password: '',
    pin: atob(atob(localStorage.getItem('_p'))),
    error: null,
    success: false,
  }),
  mounted: function() {
    this.$store.dispatch("removeSocketEvents")
  },
  beforeDestroy: function() {
    // this.socket.off('connect');
    // this.socket.off('disconnect');
  },
  computed: {
    connected: function() {return this.$store.state.isSockioConnected }
  },
  methods: {
    login: async function() {
      const auth = {username: this.username, password: this.password}
      this.success = false
      this.error = null
      try {
        this.$store.dispatch('setPin', this.pin)
        var res = await this.axios.post('/shop/login', auth,
                                {withCredentials: true}).then(res => res.data);
      } catch (err) {
        this.error = err.message
      }
      console.log(this.error)
      if (this.error != 'null') {
        if (res.id === -1) {
          console.log("login was unsuccesfull!")
          // this.error = 'Login failed!'
          this.$toast.error("Неточен корисник, лозинка или ПИН");
          this.password = ''
        } else {
          this.success = true
          this.$store.dispatch("initSocketEvents")
          this.$store.dispatch("setDeltaTime", res.webkds_time)
          this.$router.push({path: 'receipts'})
          console.log('Success logging in!')

        }
      } // no error
    },
  }
}
</script>
