<template class="w-1/3">
  <!-- containers -->
  <div class="flex flex-col p-2 w-full md:w-1/3">
    <!-- cards -->
    <div class="border rounded-lg border-grey-400 shadow text-gray-700">
      <div
        v-bind:class="{'bg-green-200': isFinished, 'bg-gray-200': !isFinished}"
        class="flex items-center px-1 py-2 justify-between w-full cursor-pointer rounded-t-lg"
        @click="isOpened = !isOpened"
      >
        <div class="flex items-center justify-between">
          <svg v-if="!isFinished" xmlns="http://www.w3.org/2000/svg" class="w-6 h-4 pl-2" viewBox="0 0 20 20" fill="currentColor"><path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"/><path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd"/></svg>
          <svg v-if="isFinished" xmlns="http://www.w3.org/2000/svg" class="w-6 h-4 pl-2" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"/></svg>
          <div class="flex flex-col justify-start">
            <span class="text-sm pl-3 font-semibold text-gray-700">{{rcpt.ago}}</span>
            <span class="text-xs pl-3 text-gray-700">{{rcpt.name}} {{rcpt.lastname}}</span>
          </div>
        </div>
        <div class="flex items-center">
          <div class="text-lg pr-2 font-semibold text-center text-gray-700">{{rcpt.turnover}} ден</div>
          <div v-if="isForOpening">
            <svg v-if="!isOpened" xmlns="http://www.w3.org/2000/svg" class="w-6 h-4 pr-2" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"/></svg>
            <svg v-if="isOpened" xmlns="http://www.w3.org/2000/svg" class="w-6 h-4 pr-2" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clip-rule="evenodd"/></svg>
          </div>
        </div>
      </div>
      <div v-bind:class="{'h-24': !forOpening}" class="overflow-hidden border-t-2 text-sm py-2 transition duration-500">
        <template v-for="item in rcpt.receipt_items" >
          <div
            :key="item.docitem_id"
            class="pl-3 flex justify-between"
          >
            <div >
            {{item.quantity}} <span class="text-xs">x</span> {{item.product_name_short}}
            </div>
            <div class="mr-3 text-gray-500">
              <span v-if="item.discount != 0"> (попуст: {{item.discount}}%)</span>
              {{Math.round(item.product_price * item.quantity * 100) / 100}}
              <span class="text-sm"> ден</span>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  data: () => ({
    isOpened: false
  }),
  props: {
    rcpt: Object
  },
  computed: {
    isFinished() {
      return (this.rcpt.finished == 0 ? false: true)
    },
    forOpening() {
      // console.log("forOpening, ",this.isOpened && this.rcpt.receipt_items.length > 2)
      return (this.isOpened && this.rcpt.receipt_items.length > 2)
    },
    isForOpening() {
      return this.rcpt.receipt_items.length > 2
    }
  }
}
</script>
