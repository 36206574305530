import Vue from 'vue';
import router from '@/router';
import axios from 'axios';

var base_url = "";

if (process.env.NODE_ENV === 'development') {
  base_url = window.origin.split(':').slice(0,2).join(':') + ":5000";
} else {
  base_url = window.origin;
}

export const ax = axios.create({
  baseURL: base_url + '/api'
});

ax.interceptors.request.use(function (config) {
  let pin = "";
  var pin_from_storage = localStorage.getItem("_p");
  if (pin_from_storage !== "") {
    pin = atob(atob(pin_from_storage));
  }

  if (pin !== "") {
    config.headers.common['PIN'] = pin;
  }
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
ax.interceptors.response.use(function (response) {
  // Do something with response data
  return response;
}, function (error) {
  Vue.$toast.clear();
  if (error.response !== 'undefined' && error.response.status === 401) {
    Vue.$toast.error("Најави се повторно!");
    router.push({path: '/'});
    // Vue.$store.dispatch("getTypeList");
  } else {
    Vue.$toast.error("Настана грешка, провери го ПИН-от и провери дали работи EasyPOS компјутерот!");
  }
  // Do something with response error
  return Promise.reject(error);
});

export default ax;
