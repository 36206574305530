<template>
  <div id="main">
    <!-- header -->
    <Header/>
    <!-- body -->
    <div class="flex justify-center">
      <div class="flex flex-wrap mt-4 mx-4 md:mx-2 w-full max-w-5xl">
        <Card v-for="rcpt in receipts" :key="rcpt.receipt_id"
          v-bind:rcpt=rcpt
        />
        <h3 v-show="receipts.length === 0" class="mx-auto text-gray-700">Празно</h3>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Card from '@/components/Card.vue';

export default {
  name: 'Receipts',
  data: () => ({
    timer: '',
    socket: window.socket
  }),
  created ()
  {
    this.timer = setInterval(this.refreshTimeAgo, 30000);
  },
  mounted: function () {
    this.$store.dispatch("setLive", true);
    this.$store.dispatch("getReceipts");
    this.$store.dispatch("initSocketEvents");
  },
  components: {
    Card,
    Header
  },
  computed: {
    receipts() {
      return this.$store.state.receipts;
    }
  },
  methods: {
    logout: async function() {
      this.$store.dispatch("logout");
      this.$router.push({path: '/'});
    },
    refreshTimeAgo: function() {
      this.$store.dispatch("refreshReceipts")
    }
  },
  beforeDestroy: function() {
    clearInterval(this.timer)
  }

}
</script>
