
import Vue from 'vue';
import VueRouter from 'vue-router';

// components for router
import Login from '@/components/Login.vue';
import Receipts from '@/components/Receipts.vue';
import Graphs from '@/components/Graphs.vue';

Vue.use(VueRouter);

const routes = [
  { path: '/', component: Login },
  { path: '/login', component: Login },
  { path: '/receipts', component: Receipts },
  { path: '/graphs', component: Graphs }
];

export default new VueRouter({
  // base: process.env.environment === 'development' ? '/' : '/ss/',
  routes
});
