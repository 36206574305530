import Vue from 'vue';
import router from './router';
import store from './store';
import App from './App.vue';
// import { Manager } from "socket.io-client";
// import VueSocketIO from 'vue-socket.io';
import '@/assets/css/tailwind.css';
import VueApexCharts from 'vue-apexcharts';
import Multiselect from 'vue-multiselect';
import Toast from 'vue-toastification';
import VueAxios from 'vue-axios';
import ax from './plugins/axios';

import 'vue-toastification/dist/index.css';

Vue.config.productionTip = true;

const moment = require('moment');
require('moment/locale/mk');

Vue.use(VueAxios, ax);
Vue.use(Toast);

Vue.use(require('vue-moment'), {
  moment
});

Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);

Vue.component('multiselect', Multiselect);

new Vue({
  render: h => h(App),
  router,
  store,
  created() {
    this.$store.commit("initialiseStore");
  }
}).$mount('#app');

