<template>
  <div id="main">
    <!-- header -->
    <Header/>
    <!-- body -->
    <div class="w-full p-2 min-w-full mx-auto">
      <div class="flex flex-wrap px-2 justify-center">

        <div class="flex h-16 items-center justify-center w-full sm:w-auto">
          <div @click="sel_x_clicked(SEL_RANGE.year)" :class="{'bg-gray-500': sel_range == this.SEL_RANGE.year}" class="cursor-pointer border-2 border-gray-500 px-3 py-1 rounded-l-full text-center">Година</div>
          <div @click="sel_x_clicked(SEL_RANGE.month)" :class="{'bg-gray-500': sel_range == this.SEL_RANGE.month}" class="cursor-pointer border-t-2 border-b-2 border-gray-500 px-3 py-1 text-center">Месец</div>
          <div @click="sel_x_clicked(SEL_RANGE.day)" :class="{'bg-gray-500': sel_range == this.SEL_RANGE.day}" class="cursor-pointer border-2 border-gray-500 px-3 py-1 rounded-r-full text-center">Ден</div>
        </div>

        <div class="flex w-64 sm:ml-4 items-center justify-center w-full sm:w-auto mb-2 sm:mb-0 ">
          <multiselect class="w-24" v-model="comp_chosen_year" :options="list_years" :multiple="false" :searchable="false" :show-labels="false" placeholder="Год." :preselect-first="true"></multiselect>
          <multiselect v-show="isMonthShown" class="ml-2 w-16" v-model="comp_chosen_month" :options="list_months" :multiple="false" :searchable="false" :show-labels="false" placeholder="Мес." :preselect-first="true"></multiselect>
          <multiselect v-show="isDayShown" class="ml-2 w-16" v-model="comp_chosen_day" :options="list_days" :multiple="false" :searchable="false" :show-labels="false" placeholder="Ден" :preselect-first="true"></multiselect>
        </div>
      </div>
      <div class="flex w-full justify-center"><div class="border rounded bg-blue-300 px-2 py-1"><span class="text-gray-800">Вкупно: </span>{{Math.round(chart_total)}} {{yAxisStr}}</div></div>
      <div class="flex ml-4 justify-start pt-2">
        <!--  graph and vertical and horiz. toggle -->
        <!-- vertical toggle -->
        <div class="relative h-16 w-8 mr-6 sm:mr-0 ">
          <div class="absolute flex transform origin-right -translate-x-full -rotate-90 items-center">
            <div @click="turnover_clicked" :class="{'bg-gray-500': yaxis_value == 0}" class="cursor-pointer border-2 border-gray-500 px-3 py-1 rounded-l-full text-center">Промет</div>
            <div @click="quantity_clicked" :class="{'bg-gray-500': yaxis_value == 1}" class="cursor-pointer border-2 border-l-0 border-gray-500 px-3 py-1 rounded-r-full text-center">Количина</div>
          </div>
        </div>
        <div class="flex flex-col w-full">
          <!-- graph -->
          <div ref="graphContainer" class="bg-gray-100 h-96 w-full" :class="{'overflow-x-scroll': chart_width !== '100%'}">
            <apexchart height="340" :width="chart_width" type="bar" :options="chart_options" :series="chart_series"></apexchart>
          </div>
          <!-- horiz. toggle -->
          <div class="flex h-16 items-center">
            <div @click="date_clicked" :class="{'bg-gray-500': xaxis_value == 0}" class="cursor-pointer border-2 border-gray-500 px-3 py-1 rounded-l-full text-center">Датум</div>
            <div @click="articles_clicked" :class="{'bg-gray-500': xaxis_value == 1}" class="cursor-pointer border-t-2 border-b-2 border-gray-500 px-3 py-1 text-center">Производи</div>
            <div @click="users_clicked" :class="{'bg-gray-500': xaxis_value == 2}" class="cursor-pointer border-2 border-gray-500 px-3 py-1 rounded-r-full text-center">Корисници</div>
          </div>
        </div>
      </div>
      <!-- selects -->

      <div class="ml-16 mb-4 text-gray-700">Филтри</div>
      <div class="ml-16 flex flex-wrap justify-start">
        <multiselect class="w-full sm:w-1/4" :value="selected_products" :options="list_products" @input="updateSelectedProduct" :multiple="true" placeholder="производ" label="product_name" track-by="product_id" :preselect-first="false" :internal-search="false" :hide-selected="true" :select-label="''" @search-change="asyncFindProducts"></multiselect>
        <multiselect class="sm:ml-2 w-full sm:w-1/4 mt-2 sm:mt-0" :value="selected_users" :options="list_users" @input="updateSelectedUser" :multiple="true" placeholder="корисник" :custom-label="user_name_lastname" track-by="user_id" :preselect-first="false"></multiselect>
        <multiselect class="sm:ml-2 w-full sm:w-1/4 mt-2 sm:mt-0" :value="selected_types" :options="list_types" @input="updateSelectedType" :multiple="true" placeholder="тип документ" label="type_name" track-by="type_id" :preselect-first="false"></multiselect>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Header from '@/components/Header.vue';

export default {
  name: 'Receipts',
  mounted: function () {
    this.$store.dispatch("getProductList");
    this.$store.dispatch("getUserList");
    this.$store.dispatch("getTypeList");
    if (!this.$store.state.graphOpenedOnce) {
      this.$store.dispatch("setGraphOpenedOnce");
      // this.setChosenDateNow();
      this.checkLive()
      this.$store.dispatch("setSelectedDate", this.getSelectedDateStr())
      this.$store.dispatch("getGraph");
    }
    this.$store.dispatch("initSocketEvents");
    this.chart_options = {...this.chart_options, yaxis: { title: {text: this.yAxisStr}}};
    this.isMounted = true;
  },
  components: {
    Header
  },
  data: () => ({
    chart_options: {
      tooltip: {
        enabled: true
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          if (val == 0) {
            return ''
          }
          return val
        },
        style: {
          fontSize: '10px',
          colors: ['#333']
        },
      },
      yaxis: {
        show: true,
        title: {
          text: ""
        }
      }
    },
    containerLength: 0,
    list_years: [2021, 2020, 2019, 2018, 2017],
    // chosen_year: 2020,
    // chosen_month: 1,
    // chosen_day: 1,
    SEL_RANGE: {
      'year': 0,
      'month': 1,
      'day': 2
    },
    // sel_range: 2, // 0 => year, 1 => month, 2 => day
    open_drop: false,
    isMounted: false
  }),
  computed: {
    receipts() {
      return this.$store.state.receipts;
    },
    yaxis_value() {
      return this.$store.state.yAxis;
    },
    xaxis_value() {
      return this.$store.state.xAxis;
    },
    chart_series() {
      return [{'data': this.$store.state.graph_data.data}];
    },
    chart_total() {
      if (this.$store.state.graph_data.total === undefined) return 0;
      return this.$store.state.graph_data.total;
    },
    chart_width() {
      if (!this.isMounted)
        return;
      var calc_length = this.$store.state.graph_data.data.length * 50 + 60
      var containerLength = this.$refs.graphContainer.clientWidth;
      // console.log("containerLength: ", containerLength);
      // console.log("chart_width: ", calc_length);
      if (containerLength > calc_length)
        calc_length = "100%";
      return calc_length;
    },
    list_months() {
      // ako e tekovna godina gi listame mesecite od tekovniot nanazad do 1
      var l_months = [];
      var d = new Date();
      var curr_year = d.getFullYear();
      var curr_month = 12;
      if (this.chosen_year == curr_year) {
        curr_month = d.getMonth() + 1;
      }
      for (var i = curr_month; i > 0; i--) {
        l_months.push(i);
      }
      return l_months;
    },
    list_days() {
      var l_days = [];
      var max_day;
      var d = new Date();
      var curr_year = d.getFullYear();
      var curr_month = d.getMonth() + 1;
      var curr_day = d.getDate();
      if (this.chosen_year == curr_year && this.chosen_month == curr_month) {
        max_day = curr_day;
      } else {
        max_day = new Date(this.chosen_year, this.chosen_month, 0).getDate();
      }
      for (var i=max_day; i>0; i--) {
        l_days.push(i);
      }
      return l_days;
    },
    max_days() {
      return new Date(this.chosen_year, this.chosen_month, 0).getDate()
    },
    isMonthShown() {
      console.log('isMonthShown called!')
      if ([this.SEL_RANGE.month, this.SEL_RANGE.day].indexOf(this.sel_range) > -1) {
        return true;
      }
      return false;
    },
    isDayShown() {
      if (this.sel_range == this.SEL_RANGE.day) {
        return true;
      }
      return false;
    },
    comp_chosen_year: {
      get () {
        return this.$store.state.chosen_year;
      },
      set (val) {
        this.$store.dispatch('setChosenYear', val);
      }
    },
    comp_chosen_month: {
      get () {
        return this.$store.state.chosen_month;
      },
      set (val) {
        this.$store.dispatch('setChosenMonth', val);
      }
    },
    comp_chosen_day: {
      get () {
        return this.$store.state.chosen_day;
      },
      set (val) {
        this.$store.dispatch('setChosenDay', val);
      }
    },
    ...mapState(['selected_products', 'selected_users', 'selected_types', 'list_products', 'list_users', 'list_types', 'numSockioMsgRcvd', 'live', 'yAxis', 'chosen_year', 'chosen_month', 'chosen_day', 'sel_range', 'yAxisStr']),

  },
  watch: {
    getContainerLength() {
      this.$nextTick(() => {
        this.containerLength = this.$refs.graphContainer.clientWidth;
        // console.log(this.containerLength);
      })
    },
    numSockioMsgRcvd() {
      // proverka dali sme odbrale denesen den, osvezuvanje se pravi samo za denesen den
      if (this.checkLive()) {
        // console.log("Refreshing graph!");
        this.$store.dispatch("getGraph");
      }
    },
    list_days() {
      if (this.chosen_day > this.list_days[0]) {
        this.chosen_day = this.$store.dispatch('setChosenDay', this.list_days[0]);
      }
    },
    chosen_day() {
      this.checkLive()
      this.$store.dispatch("setSelectedDate", this.getSelectedDateStr())
      this.$store.dispatch("getGraph");
    },
    chosen_month() {
      this.checkLive()
      this.$store.dispatch("setSelectedDate", this.getSelectedDateStr())
      this.$store.dispatch("getGraph");
    },
    chosen_year() {
      this.checkLive()
      this.$store.dispatch("setSelectedDate", this.getSelectedDateStr())
      this.$store.dispatch("getGraph");
    }
  },
  methods: {
    ...mapActions(['updateSelectedProduct', 'updateSelectedUser', 'updateSelectedType',
                   'setChosenYear', 'setChosenMonth', 'setChosenDay']),
    logout: async function() {
      this.$store.dispatch("logout");
      this.$router.push({path: '/'});
    },
    turnover_clicked: function() {
      // zaradi nested reaktivnosta mora vaka
      this.$store.dispatch('set_yaxis', 0);
      this.chart_options = {...this.chart_options, yaxis: { title: {text: this.yAxisStr}}};
      // proba, ovde raboti, vo watch ne saka
      // console.log(this.$refs.graphContainer.clientWidth);
    },
    quantity_clicked: function() {
      // zaradi nested reaktivnosta mora vaka
      // this.chart_options.yaxis.title.text = "Пар.";
      this.$store.dispatch('set_yaxis', 1)
      this.chart_options = {...this.chart_options, yaxis: { title: {text: this.yAxisStr}}};
    },
    date_clicked: function() {
      this.$store.dispatch('set_xaxis', 0);
    },
    articles_clicked: function() {
      this.$store.dispatch('set_xaxis', 1);
    },
    users_clicked: function() {
      this.$store.dispatch('set_xaxis', 2);
    },
    getSelectedDateStr() {
      var date_str = this.chosen_year.toString(10);
      if (this.isDayShown) {
        return date_str + "-" + this.prependZero(this.chosen_month) + '-' + this.prependZero(this.chosen_day);
      }
      if (this.isMonthShown) {
        return date_str + "-" + this.prependZero(this.chosen_month);
      }
      return date_str;
    },
    prependZero(val) {
      if (val >= 1 && val <= 9) {
        return '0' + val;
      }
      return val
    },
    sel_x_clicked(val) {
      console.log('sel_x_clicked with val: ', this.sel_range, val);
      if (this.sel_range != val) {
        console.log('inside the if val: ', this.sel_range, val);
        this.$store.commit('SET_SEL_RANGE', val);
        this.$store.dispatch("setSelectedDate", this.getSelectedDateStr());
        this.$store.dispatch("getGraph");
      }
      this.checkLive();
    },
    user_name_lastname(user) {
      return user.name + ' ' + user.lastname;
    },
    checkLive() {
      var d = new Date();
      var curr_year = d.getFullYear();
      var curr_month = d.getMonth() + 1;
      var curr_day = d.getDate();
      if (this.chosen_year == curr_year &&
          this.chosen_month == curr_month &&
          this.chosen_day == curr_day &&
          this.sel_range == this.SEL_RANGE.day
      ) {
        this.$store.dispatch("setLive", true);
        return true;
      } else {
        this.$store.dispatch("setLive", false);
        return false;
      }
    },
    asyncFindProducts(query) {
      console.log("asyncFindProducts query: ", query);
      this.$store.dispatch('getProductList', query).then(console.log(this.list_products));
    }
  }

}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
