<template>
  <div class="flex justify-between w-full shadow-md bg-gray-800 items-center">
    <div>
      <router-link to="/receipts">
        <button :class="currentPage.includes('receipts') ? 'bg-gray-700': ''" class="ml-2 py-3 px-2 text-base sm:text-xl sm:py-1 text-center font-semibold tracking-regular text-gray-200 hover:bg-gray-600">Сметки</button>
      </router-link>
      <router-link to="/graphs">
        <button :class="currentPage.includes('graphs') ? 'bg-gray-700': ''" class="ml-2 py-3 px-2 text-base sm:text-xl sm:py-1 text-center font-semibold tracking-regular text-gray-200 hover:bg-gray-600">Анализа</button>
      </router-link>
    </div >
    <div class="flex items-center">
      <span v-show="live" class="text-grey-800 text-xs mr-1 px-1 py-0 border rounded bg-yellow-500 border-yellow-500">во живо</span>
      <button @click="logout()" class="flex items-center px-2 py-3 sm:py-1 mr-1 hover:bg-gray-600">
        <span class="text-white mr-2 hidden sm:block">Одјава</span>
        <svg class="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"/></svg>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Header',
  methods: {
    logout: async function() {
      this.$store.dispatch("logout");
      this.$router.push({path: '/'});
    }
  },
  computed: {
    currentPage() {
      return this.$route.path;
    },
    ...mapState(['live'])
  }

}
</script>
