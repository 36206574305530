import Vue from 'vue';
import Vuex from 'vuex';
// import {ax} from '@/common/utils.js';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    isSocketCreated: false,
    isSockioConnected: false,
    socket: "",
    numSockioMsgRcvd: 0,  // counter za dobieni poraki, spored nego reagirame primer vo Graphs.vue
    receipts: [],
    xAxis: 0,
    yAxis: 0,
    yAxisStr: 'ден.',
    graph_data: { data: [] },
    selected_products: [],
    selected_users: [],
    selected_types: [],
    list_products: [],
    list_users: [],
    list_types: [],
    selected_date: "",
    live: false,
    socket_domain: "",
    pin: "",
    delta_time: 0,
    graphOpenedOnce: false,
    chosen_year: 2020,
    chosen_month: 1,
    chosen_day: 1,
    sel_range: 2
  },
  getters: {
    // sockioMsgRcvd: state => state.isSockioMsgRcvd
  },
  mutations: {
    initialiseStore(state) {
      console.log("initialiseStore..");

      var d = new Date();
      state.chosen_year = d.getFullYear();
      state.chosen_month = d.getMonth() + 1;
      state.chosen_day = d.getDate();

      var pin_from_storage = localStorage.getItem("_p");
      if (pin_from_storage !== "") {
        state.pin = atob(atob(pin_from_storage));
      }
      var delta_time_from_storage = localStorage.getItem("delta_time");
      if (delta_time_from_storage !== "") {
        state.delta_time = parseInt(delta_time_from_storage);
      }
      if (process.env.NODE_ENV === 'development') {
        state.socket_domain = window.origin.split(':').slice(0,2).join(':') + ":5000";
      } else {
        state.socket_domain = window.origin;
      }
    },
    SOCKET_CREATED (state, val) {
      state.isSocketCreated = val;
    },
    SOCKET_connect (state) {
      console.log('socket connected!');
      state.isSockioConnected = true;
    },

    SOCKET_disconnect (state) {
      console.log('socket disconnected!');
      state.isSockioConnected = false;
    },

    SOCKET_neworders (state, msg) {
      console.log('neworders arrived');
      state.numSockioMsgRcvd++;
      state.newOrders = msg;
      this.dispatch("getReceipts");
    },
    SET_RECEIPTS (state, receipts_json) {
      var receipts = receipts_json.receipts;
      if (typeof receipts == "undefined") {
        receipts = [];
      }
      state.receipts = receipts;
      this.commit("REFRESH_RECEIPT_AGO");
    },
    REFRESH_RECEIPT_AGO (state) {
      if (typeof state.receipts != "undefined") {
        // console.log("refreshed receipts time ago!");
        var moment = Vue.prototype.$moment;
        for (var i=0; i < state.receipts.length; i++) {
          var rcpt = state.receipts[i];
          // substract delta interval for correct time ago times
          Vue.set(rcpt, 'ago', moment(rcpt.time_stamp - state.delta_time).fromNow());
        }
      }

    },
    SET_GRAPH_DATA(state, graph_json) {
      state.graph_data = graph_json;
    },
    SET_YAXIS (state, val) {
      state.yAxis = val;
      if (val == 0) {
        state.yAxisStr = "ден.";
      } else {
        state.yAxisStr = "пар.";
      }
      this.dispatch("getGraph");
    },
    SET_XAXIS (state, val) {
      state.xAxis = val;
      this.dispatch("getGraph");
    },
    UPDATE_SELECTED_PRODUCT(state, val) {
      state.selected_products = val;
    },
    UPDATE_SELECTED_USER(state, val) {
      state.selected_users = val;
    },
    UPDATE_SELECTED_TYPE(state, val) {
      state.selected_types = val;
    },
    SET_LIST_PRODUCTS (state, product_list_json) {
      state.list_products = product_list_json;
    },
    SET_LIST_USERS (state, user_list_json) {
      state.list_users = user_list_json;
    },
    SET_LIST_TYPES (state, type_list_json) {
      state.list_types = type_list_json;
    },
    SET_SELECTED_DATE(state, val) {
      state.selected_date = val;
    },
    SET_LIVE(state, val) {
      state.live = val;
    },
    SET_PIN(state, val) {
      state.pin = val;
      localStorage.setItem("_p", btoa(btoa(state.pin)));
      console.log('localStorage setItem for pin: ', state.pin);
    },
    SET_DELTA_TIME(state, val) {
      state.delta_time = val;
      localStorage.setItem("delta_time", val);
      console.log('localStorage setItem for delta_time: ', state.delt_time);
    },
    SET_GRAPH_OPENED_ONCE(state) {
      state.graphOpenedOnce = true;
    },
    SET_CHOSEN_YEAR(state, val) {
      state.chosen_year = val;
    },
    SET_CHOSEN_MONTH(state, val) {
      state.chosen_month = val;
    },
    SET_CHOSEN_DAY(state, val) {
      state.chosen_day = val;
    },
    SET_SEL_RANGE(state, val) {
      state.sel_range = val;
    }
  },
  actions: {
    getReceipts ({ commit }) {
      Vue.axios.get('/shop/receipts',
             {withCredentials: true})
        .then(response => {
          commit('SET_RECEIPTS', response.data);
        });
    },
    refreshReceipts({ commit }) {
      commit('REFRESH_RECEIPT_AGO');
    },
    logout ({ commit }) {
      Vue.axios.get('/logout',
             {withCredentials: true})
        .then(() => {
          // setting receipt to blank
          commit('SET_RECEIPTS', {"receipts": []});
        });
    },
    getGraph ({ commit }) {
      var i = 0;
      var filter_products = [];
      for (i = 0; i < this.state.selected_products.length; i++) {
        filter_products.push(this.state.selected_products[i].product_id);
      }
      var filter_users = [];
      for (i = 0; i < this.state.selected_users.length; i++) {
        filter_users.push(this.state.selected_users[i].user_id);
      }
      var filter_types = [];
      for (i = 0; i < this.state.selected_types.length; i++) {
        filter_types.push(this.state.selected_types[i].type_id);
      }
      var post_data = {
        "date": this.state.selected_date,
        "xaxis": this.state.xAxis,
        "filter_products": filter_products,
        "filter_users": filter_users,
        "filter_types": filter_types,
        "yaxis": this.state.yAxis
      };
      Vue.axios.post('/shop/stats', post_data,
                {withCredentials: true})
        .then(response => {
          commit('SET_GRAPH_DATA', response.data);
        });
    },
    set_yaxis ({ commit }, val) {
      if (val !== this.state.yAxis) {
        commit('SET_YAXIS', val);
      }
    },
    set_xaxis ({ commit }, val) {
      if (val !== this.state.xAxis) {
        commit('SET_XAXIS', val);
      }
    },
    updateSelectedProduct({ commit }, value) {
      commit('UPDATE_SELECTED_PRODUCT', value);
      this.dispatch('getGraph');
      console.log(this.state.selected_products);
    },
    getProductList ({ commit }, name) {
      Vue.axios.get('/list/products',
                    {withCredentials: true, params: {name: name}})
        .then(response => {
          commit('SET_LIST_PRODUCTS', response.data);
        });
    },
    updateSelectedUser({ commit }, value) {
      commit('UPDATE_SELECTED_USER', value);
      this.dispatch('getGraph');
      console.log(this.state.selected_users);
    },
    getUserList ({ commit }) {
      Vue.axios.get('/list/users',
                {withCredentials: true})
        .then(response => {
          commit('SET_LIST_USERS', response.data);
        });
    },
    updateSelectedType({ commit }, value) {
      commit('UPDATE_SELECTED_TYPE', value);
      this.dispatch('getGraph');
      console.log(this.state.selected_types);
    },
    getTypeList ({ commit }) {
      Vue.axios.get('/list/types',
                {withCredentials: true})
        .then(response => {
          commit('SET_LIST_TYPES', response.data);
        });
    },
    setSelectedDate({ commit }, val) {
      commit('SET_SELECTED_DATE', val);
    },
    setLive({ commit }, val) {
      // console.log("setLive: ", val);
      commit('SET_LIVE', val);
    },
    setPin({ commit }, val) {
      console.log("setPin: ", val);
      commit('SET_PIN', val);
    },
    removeSocketEvents({ commit }) {
      console.log("socket, called removeSocketEvents");
      if (this.state.isSocketCreated) {
        try {
          console.log("socket, removing socket: ", this.state.socket);
          this.state.socket.close();
          this.state.socket.off('connect');
          this.state.socket.off('disconnect');
          this.state.socket.off('neworders');
          console.log("socket, removed all socket events");
        } catch(error) {  console.log("Error closing socket io"); }
        commit('SOCKET_CREATED', false);
      }
    },
    initSocketEvents({ commit }) {
      console.log("socket, called initSocketEvents pin: ", this.state.pin);
      if (!this.state.isSocketCreated) {
        console.log("socket, create socket and attach events");
        var socketIO = require('socket.io-client');

        var socket_path = "";
        if (process.env.NODE_ENV === 'development') {
          socket_path = "/socket.io";
        } else {
          socket_path = "/socket.io/" + this.state.pin;
        }

        this.state.socket = socketIO(this.state.socket_domain, {
          // "path": "/socket.io/" + this.state.pin,
          "path": socket_path,
          "forceNew": true
        });
        this.state.socket.on('connect', () => {
          commit('SOCKET_connect');
        });

        this.state.socket.on('disconnect', () => {
          commit('SOCKET_disconnect');
        });

        this.state.socket.on('neworders', (data) => {
          commit('SOCKET_neworders', data);
        });
        commit('SOCKET_CREATED', true);
      }
    },
    setDeltaTime({ commit }, webkds_time) {
      var now = new Date().getTime();
      commit('SET_DELTA_TIME', webkds_time - now);
    },
    setGraphOpenedOnce({ commit }) {
      commit('SET_GRAPH_OPENED_ONCE');
    },
    setChosenYear({ commit }, val) {
      commit('SET_CHOSEN_YEAR', val);
    },
    setChosenMonth({ commit }, val) {
      commit('SET_CHOSEN_MONTH', val);
    },
    setChosenDay({ commit }, val) {
      commit('SET_CHOSEN_DAY', val);
    }
  } // actions
});

export default store;
